import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import './offers.scss';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>Type Library for Offers v2</h1>
    <p>Here, you find all types associated with requests to, and responses from Enturs offers v2</p>
    <h4>Base URL: https://api.entur.io</h4>
    <h2>Request endpoints and request bodies:</h2>
    <ExpandablePanel title='/offers/v2/search/stop-places [method: POST]' mdxType="ExpandablePanel">
    <h5>RequestBody:</h5>
    <h4><a href='#searchstopplacesrequest'>SearchStopPlacesRequest</a></h4>
    </ExpandablePanel>
    <ExpandablePanel title='/offers/v2/search/zones [method: POST]' mdxType="ExpandablePanel">
    <h5>RequestBody:</h5>
    <h4><a href='#searchzonesrequest'>SearchZonesRequest</a></h4>
    </ExpandablePanel>
    <ExpandablePanel title='/offers/v2/search/trip-pattern [method: POST]' mdxType="ExpandablePanel">
    <h5>RequestBody:</h5>
    <h4><a href='#searchtrippatternrequest'>SearchTripPatternRequest</a></h4>
    </ExpandablePanel>
    <ExpandablePanel title='/offers/v2/search/trip-pattern/[id] [method: UPDATE]' mdxType="ExpandablePanel">
    <h5>RequestBody:</h5>
    <h4><a href='#updatesearchtrippatternrequest'>UpdateSearchTripPatternRequest</a></h4>
    </ExpandablePanel>
    <h2>Response:</h2>
    <ExpandablePanel title='SearchResponse' mdxType="ExpandablePanel">
    <h5>ResponseBody:</h5>
    <h4><a href='#searchresponse'>SearchResponse</a></h4>
    </ExpandablePanel>
    <br />
    <h2>Types</h2>
    <h6 className='hiddenLink' id='accommodationfacilityenum'>AccommodationFacilityEnum</h6>
    <ExpandablePanel className='expandable' title='AccommodationFacilityEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>SLEEPER</p>
      <p>SEATING</p>
      <p>DOUBLE_SLEEPER</p>
      <p>SINGLE_SLEEPER</p>
      <p>SPECIAL_SLEEPER</p>
      <p>COUCHETTE</p>
      <p>SINGLE_COUCHETTE</p>
      <p>DOUBLE_COUCHETTE</p>
      <p>RECLINING_SEAT</p>
      <p>BABY_COMPARTMENT</p>
      <p>FAMILY_COMPARTMENT</p>
      <p>PANORAMA_COACH</p>
      <p>PULLMAN_COACH</p>
      <p>STANDING</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='accommodationfacilityforrecommendationsenum'>AccommodationFacilityForRecommendationsEnum</h6>
    <ExpandablePanel className='expandable' title='AccommodationFacilityForRecommendationsEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>SLEEPER</p>
      <p>SEATING</p>
      <p>COUCHETTE</p>
      <p>ANY_FACILITY_SET</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='accommodationforservicejourney'>accommodationforservicejourney</h6>
    <ExpandablePanel className='expandable' id='AccommodationForServiceJourney' title='AccommodationForServiceJourney' mdxType="ExpandablePanel">
      <h5><a href='#servicejourneysummary'>serviceJourney: ServiceJourneySummary </a></h5>
      <h5><a href='#accommodationfacilityenum'>accommodation: Seq[AccommodationFacilityEnum]</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='authority'>authority</h6>
    <ExpandablePanel className='expandable' id='Authority' title='Authority' mdxType="ExpandablePanel">
      <span>NeTEx-id of Authority.</span>
      <h5>id: String </h5>
      <span>Name of Authority.</span>
      <h5>name: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='baggagetypeenum'>BaggageTypeEnum</h6>
    <ExpandablePanel className='expandable' title='BaggageTypeEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>HANDBAG</p>
      <p>HAND_LUGGAGE</p>
      <p>SMALL_SUITCASE</p>
      <p>SUITCASE</p>
      <p>TRUNK</p>
      <p>OVERSIZE_ITEM</p>
      <p>BICYCLE</p>
      <p>SPORTING_EQUIPMENT</p>
      <p>SKIS</p>
      <p>MUSICAL_INSTRUMENT</p>
      <p>PUSH_CHAIR</p>
      <p>MOTORIZED_WHEELCHAIR</p>
      <p>LARGE_MOTORIZED_WHEELCHAIR</p>
      <p>WHEELCHAIR</p>
      <p>SMALL_ANIMAL</p>
      <p>ANIMAL</p>
      <p>GAME</p>
      <p>MOTORCYCLE</p>
      <p>OTHER</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='cappeddiscountoption'>cappeddiscountoption</h6>
    <ExpandablePanel className='expandable' id='CappedDiscountOption' title='CappedDiscountOption' mdxType="ExpandablePanel">
      <h5><a href='#reftype'>cappedDiscountRightRef: RefType </a></h5>
      <h5><a href='#reftype'>cappedDiscountRuleRef: RefType </a></h5>
      <h5><a href='#cappingalternative'>alternatives: Option[Seq[CappingAlternative]] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='cappingalternative'>cappingalternative</h6>
    <ExpandablePanel className='expandable' id='CappingAlternative' title='CappingAlternative' mdxType="ExpandablePanel">
      <span>A pseudo random string that is used to select what alternative to use when purchasing an offer.</span>
      <h5>selectableId: String </h5>
      <h5><a href='#pricesummary'>discount: PriceSummary </a></h5>
      <span>The start date of this alternative.</span>
      <h5>startDateTime: OffsetDateTime </h5>
      <span>The end date of this alternative.</span>
      <h5>endDateTime: OffsetDateTime</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='cappingspecification'>cappingspecification</h6>
    <ExpandablePanel className='expandable' id='CappingSpecification' title='CappingSpecification' mdxType="ExpandablePanel">
      <span>Reference to a CappedDiscountRight.</span>
      <h5>cappedDiscountRightId: String </h5>
      <span>Reference to the fare contract for this traveller.</span>
      <h5>fareContractId: String </h5>
      <h5><a href='#customerref'>customerRef: CustomerRef </a></h5>
      <span>To avoid too many similar alternatives you can specify a minimum period between the start date of each alternative.</span>
      <h5>minimumDurationBetweenAlternatives: Option[String]  </h5>
      <span>Specify a minimum alternative duration oo avoid alternatives that doesn't last long enough in the future to be useful.</span>
      <h5>minimumAlternativeDuration: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='carnet'>carnet</h6>
    <ExpandablePanel className='expandable' id='Carnet' title='Carnet' mdxType="ExpandablePanel">
      <h5>amount: Int </h5>
      <h5>duration: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='categoryspec'>categoryspec</h6>
    <ExpandablePanel className='expandable' id='CategorySpec' title='CategorySpec' mdxType="ExpandablePanel">
      <h5><a href='#typeofrecommendationenum'>typesOfRecommendation: Seq[TypeOfRecommendationEnum] </a></h5>
      <h5><a href='#durationenum'>durationTypes: Option[Seq[DurationEnum]]  </a></h5>
      <h5><a href='#fareclassenum'>fareClasses: Option[Seq[FareClassEnum]]  </a></h5>
      <h5><a href='#accommodationfacilityforrecommendationsenum'>facilitySet: Option[Seq[AccommodationFacilityForRecommendationsEnum]]  </a></h5>
      <h5>specifiedProducts: Option[Seq[String]] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='comparisonoperatorenum'>ComparisonOperatorEnum</h6>
    <ExpandablePanel className='expandable' title='ComparisonOperatorEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>EQ</p>
      <p>NE</p>
      <p>GT</p>
      <p>LT</p>
      <p>GE</p>
      <p>LE</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='conditionsummary'>conditionsummary</h6>
    <ExpandablePanel className='expandable' id='ConditionSummary' title='ConditionSummary' mdxType="ExpandablePanel">
      <span>Whether the ticket is refundable.</span>
      <h5>isRefundable: Option[Boolean]  </h5>
      <span>Whether the ticket is exchangeable.</span>
      <h5>isExchangeable: Option[Boolean] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='customerref'>customerref</h6>
    <ExpandablePanel className='expandable' id='CustomerRef' title='CustomerRef' mdxType="ExpandablePanel">
      <span>Reference to the account id within the account based ticketing module.</span>
      <h5>accountBasedTicketingCustomerRef: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='datamanagedobjectdata'>datamanagedobjectdata</h6>
    <ExpandablePanel className='expandable' id='DataManagedObjectData' title='DataManagedObjectData' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage]</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='datedservicejourney'>datedservicejourney</h6>
    <ExpandablePanel className='expandable' id='DatedServiceJourney' title='DatedServiceJourney' mdxType="ExpandablePanel">
      <span>A unique departure ID.</span>
      <h5>datedServiceJourneyId: String </h5>
      <span>A reference to the original dated service journey ID.</span>
      <h5>originalDatedServiceJourneyId: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='discountright'>discountright</h6>
    <ExpandablePanel className='expandable' id='DiscountRight' title='DiscountRight' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>Used for selecting a unique element in the configurations</span>
      <h5>selectableId: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage] </a></h5>
      <h5><a href='#organisationconfig'>organisation: OrganisationConfig </a></h5>
      <span>The price(s) of the sub configurations below this node. Multiple prices is supported in case of prices in multiple currencies.</span>
      <h5><a href='#price'>prices: Seq[Price] </a></h5>
      <span>The price contribution shows how much this node contributes on the total price.</span>
      <h5><a href='#price'>priceContributions: Seq[Price] </a></h5>
      <h5><a href='#discountusageparameters'>parameters: Option[DiscountUsageParameters]  </a></h5>
      <span>True if this configuration is optional to buy, false if it's required.</span>
      <h5>optional: Boolean </h5>
      <span>The id of the entitlement product that allowed the sale discount right to be used. Only used if the fare product configuration contains a sale discount right, and the traveller had an entitlement product.</span>
      <h5>originatingFromProductId: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='discountrightsummary'>discountrightsummary</h6>
    <ExpandablePanel className='expandable' id='DiscountRightSummary' title='DiscountRightSummary' mdxType="ExpandablePanel">
      <h5>id: String </h5>
      <h5>version: String </h5>
      <h5><a href='#pricesummary'>price: PriceSummary </a></h5>
      <h5>name: String </h5>
      <h5>description: String </h5>
      <h5>originatingFromProductId: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='discountusageparameters'>discountusageparameters</h6>
    <ExpandablePanel className='expandable' id='DiscountUsageParameters' title='DiscountUsageParameters' mdxType="ExpandablePanel">
      <span>Reference to reserving parameters with price information.</span>
      <h5><a href='#pricedusageparameter'>reserving: Seq[PricedUsageParameter] </a></h5>
      <span>Reference to entitlement given parameters with price information.</span>
      <h5><a href='#pricedusageparameter'>entitlementGiven: Seq[PricedUsageParameter]</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='distributionparameters'>distributionparameters</h6>
    <ExpandablePanel className='expandable' id='DistributionParameters' title='DistributionParameters' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage] </a></h5>
      <span>The price(s) of the sub configurations below this node. Multiple prices is supported in case of prices in multiple currencies.</span>
      <h5><a href='#price'>prices: Seq[Price] </a></h5>
      <span>The price contribution shows how much this node contributes on the total price.</span>
      <h5><a href='#price'>priceContributions: Seq[Price] </a></h5>
      <span>Reference to a FullfillmentMethod with price information.</span>
      <h5>fulfillmentMethod: Option[String]  </h5>
      <span>Reference to a DistributionChannel with price information.</span>
      <h5>distributionChannel: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='durationenum'>DurationEnum</h6>
    <ExpandablePanel className='expandable' title='DurationEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>SINGLE_TRIP</p>
      <p>RETURN_TRIP</p>
      <p>CARNET</p>
      <p>DAY_PASS</p>
      <p>WEEKLY_PASS</p>
      <p>WEEKEND_PASS</p>
      <p>MONTHLY_PASS</p>
      <p>SEASON_TICKET</p>
      <p>PROFILE_MEMBERSHIP</p>
      <p>OPEN_ENDED</p>
      <p>OTHER</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='estimatedcall'>estimatedcall</h6>
    <ExpandablePanel className='expandable' id='EstimatedCall' title='EstimatedCall' mdxType="ExpandablePanel">
      <span>The date the estimated call is valid for.</span>
      <h5>date: String </h5>
      <h5><a href='#quay'>quay: Option[Quay] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='existingticket'>existingticket</h6>
    <ExpandablePanel className='expandable' id='ExistingTicket' title='ExistingTicket' mdxType="ExpandablePanel">
      <span>The netex-id of the entity.</span>
      <h5>id: String </h5>
      <span>The zones the existing ticket is valid for</span>
      <h5>zones: Option[Seq[String]]  </h5>
      <span>ServiceJourneys, not implemented</span>
      <h5>serviceJourneys: Option[Seq[String]] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='explaincapacity'>explaincapacity</h6>
    <ExpandablePanel className='expandable' id='ExplainCapacity' title='ExplainCapacity' mdxType="ExpandablePanel">
      <span>ID of a FareProduct</span>
      <h5>fareProductId: String </h5>
      <span>ID of a DatedServiceJourney</span>
      <h5>datedServiceJourneyId: Option[String]  </h5>
      <span>Available capacity</span>
      <h5>capacity: Option[Int]  </h5>
      <h5><a href='#reservationstatusenum'>status: ReservationStatusEnum </a></h5>
      <h5><a href='#serviceenum'>origin: Seq[ServiceEnum]</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='explainchoice'>explainchoice</h6>
    <ExpandablePanel className='expandable' id='ExplainChoice' title='ExplainChoice' mdxType="ExpandablePanel">
      <span>The id of the choice</span>
      <h5>id: String </h5>
      <span>Can be UserProfile, GeographicalInterval etc.</span>
      <h5>choiceType: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='explaincondition'>explaincondition</h6>
    <ExpandablePanel className='expandable' id='ExplainCondition' title='ExplainCondition' mdxType="ExpandablePanel">
      <span>The id of the condition parameter</span>
      <h5>id: String </h5>
      <span>Is the condition valid or not</span>
      <h5>valid: Boolean </h5>
      <span>Can be And, Or, ZoneParameter etc.</span>
      <h5>conditionType: String </h5>
      <span>Values that the condition is evaluated against</span>
      <h5>values: Option[Seq[String]]  </h5>
      <h5><a href='#explaincondition'>children: Option[Seq[ExplainCondition]]  </a></h5>
      <h5><a href='#comparisonoperatorenum'>comparisonOperator: Option[ComparisonOperatorEnum]  </a></h5>
      <span>Values from the travel scope search that the condition is evaluated against</span>
      <h5>travelScopeValues: Option[Seq[String]] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='explaininfo'>explaininfo</h6>
    <ExpandablePanel className='expandable' id='ExplainInfo' title='ExplainInfo' mdxType="ExpandablePanel">
      <h5><a href='#explainnode'>explainNodes: Option[Seq[ExplainNode]]  </a></h5>
      <h5><a href='#explaincapacity'>explainCapacity: Option[Seq[ExplainCapacity]] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='explainnode'>explainnode</h6>
    <ExpandablePanel className='expandable' id='ExplainNode' title='ExplainNode' mdxType="ExpandablePanel">
      <span>id of the object</span>
      <h5>id: String </h5>
      <span>version of the object</span>
      <h5>version: String </h5>
      <span>The reason why this node was invalid in a human readable format</span>
      <h5>reason: String </h5>
      <span>Error message if the node was invalid due to an error</span>
      <h5>errorMessage: Option[String]  </h5>
      <span>The service journeys the node was evaluated against</span>
      <h5>serviceJourneyIds: Option[Seq[String]]  </h5>
      <h5><a href='#explaincondition'>conditions: Option[Seq[ExplainCondition]]  </a></h5>
      <h5><a href='#explainchoice'>choices: Option[Seq[ExplainChoice]]  </a></h5>
      <h5><a href='#explainnode'>children: Option[Seq[ExplainNode]] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='explainspecification'>explainspecification</h6>
    <ExpandablePanel className='expandable' id='ExplainSpecification' title='ExplainSpecification' mdxType="ExpandablePanel">
      <span>The sales package ids you want to debug</span>
      <h5>salesPackageIds: Option[Seq[String]]  </h5>
      <span>The fare product ids you want to debug</span>
      <h5>fareProductIds: Option[Seq[String]] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='fareclassenum'>FareClassEnum</h6>
    <ExpandablePanel className='expandable' title='FareClassEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>FIRST_CLASS</p>
      <p>SECOND_CLASS</p>
      <p>THIRD_CLASS</p>
      <p>ECONOMY_CLASS</p>
      <p>BUSINESS_CLASS</p>
      <p>TURISTA</p>
      <p>PREFERENTE</p>
      <p>PREMIUM_CLASS</p>
      <p>ANY</p>
      <p>STANDARD_CLASS</p>
      <p>UNKNOWN</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='fareproductconfiguration'>fareproductconfiguration</h6>
    <ExpandablePanel className='expandable' id='FareProductConfiguration' title='FareProductConfiguration' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>Used for selecting a unique element in the configurations</span>
      <h5>selectableId: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage] </a></h5>
      <h5><a href='#organisationconfig'>organisation: OrganisationConfig </a></h5>
      <span>The price(s) of the sub configurations below this node. Multiple prices is supported in case of prices in multiple currencies.</span>
      <h5><a href='#price'>prices: Seq[Price] </a></h5>
      <span>The price contribution shows how much this node contributes on the total price.</span>
      <h5><a href='#price'>priceContributions: Seq[Price] </a></h5>
      <h5><a href='#properties'>properties: Option[Properties]  </a></h5>
      <h5><a href='#usageparameters'>parameters: Option[UsageParameters]  </a></h5>
      <span>Reference to the legs this configuration is for.</span>
      <h5>serviceJourneyIds: Seq[String] </h5>
      <span>Reference to the legs this configuration is for.</span>
      <h5><a href='#datedservicejourney'>datedServiceJourneys: Option[Seq[DatedServiceJourney]]  </a></h5>
      <span>True if this configuration is optional to buy, false if it's required.</span>
      <h5>optional: Boolean </h5>
      <span>Reference to a Validable Element and its configuration.</span>
      <h5><a href='#validableelementconfiguration'>validableElements: Seq[ValidableElementConfiguration] </a></h5>
      <span>A classification of this fare product</span>
      <h5>typeOfFareProduct: Option[String]  </h5>
      <h5><a href='#conditionsummary'>conditionSummary: Option[ConditionSummary]  </a></h5>
      <h5><a href='#discountright'>discountRight: Option[DiscountRight]  </a></h5>
      <span>The id of the entitlement product that allowed the sale discount right to be used. Only used if the fare product configuration contains a sale discount right, and the traveller had an entitlement product.</span>
      <h5>originatingFromProductId: Option[String]  </h5>
      <h5><a href='#pointtopointvalidity'>pointToPointValidity: Option[PointToPointValidity]  </a></h5>
      <h5><a href='#linevalidity'>lineValidity: Option[LineValidity]  </a></h5>
      <h5><a href='#supplementproducttypeenum'>supplementProductType: Option[SupplementProductTypeEnum]  </a></h5>
      <h5><a href='#zonalvalidity'>zonalValidity: Option[ZonalValidity]  </a></h5>
      <span>Amount of priced unit</span>
      <h5>amount: Option[Int]  </h5>
      <h5><a href='#producttypeenum'>productType: Option[ProductTypeEnum]  </a></h5>
      <span>Specific parameter assignments</span>
      <h5><a href='#specificparameterassignment'>specificParameterAssignments: Option[Seq[SpecificParameterAssignment]] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='farestructureelementconfiguration'>farestructureelementconfiguration</h6>
    <ExpandablePanel className='expandable' id='FareStructureElementConfiguration' title='FareStructureElementConfiguration' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage] </a></h5>
      <span>The price(s) of the sub configurations below this node. Multiple prices is supported in case of prices in multiple currencies.</span>
      <h5><a href='#price'>prices: Seq[Price] </a></h5>
      <span>The price contribution shows how much this node contributes on the total price.</span>
      <h5><a href='#price'>priceContributions: Seq[Price] </a></h5>
      <h5><a href='#properties'>properties: Option[Properties]  </a></h5>
      <h5><a href='#usageparameters'>parameters: Option[UsageParameters]  </a></h5>
      <span>Reference to the legs this configuration is for.</span>
      <h5>serviceJourneyIds: Seq[String] </h5>
      <span>Reference to the legs this configuration is for.</span>
      <h5><a href='#datedservicejourney'>datedServiceJourneys: Option[Seq[DatedServiceJourney]]  </a></h5>
      <h5><a href='#geointervaldata'>interval: Option[GeoIntervalData]  </a></h5>
      <h5><a href='#datamanagedobjectdata'>qualityFactor: Option[DataManagedObjectData]  </a></h5>
      <h5><a href='#datamanagedobjectdata'>distanceMatrixElement: Option[DataManagedObjectData]  </a></h5>
      <span>Quotas for the configuration</span>
      <h5><a href='#quota'>quotas: Seq[Quota] </a></h5>
      <span>Specific parameter assignments</span>
      <h5><a href='#specificparameterassignment'>specificParameterAssignments: Option[Seq[SpecificParameterAssignment]] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='geographicalvaliditycovered'>geographicalvaliditycovered</h6>
    <ExpandablePanel className='expandable' id='GeographicalValidityCovered' title='GeographicalValidityCovered' mdxType="ExpandablePanel">
      <h5>serviceJourneys: Option[Seq[String]]  </h5>
      <h5><a href='#pointtopointvalidity'>pointToPointValidity: Option[PointToPointValidity]  </a></h5>
      <h5><a href='#zonalvalidity'>zonalValidity: Option[ZonalValidity] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='geographicalvalidity'>geographicalvalidity</h6>
    <ExpandablePanel className='expandable' id='GeographicalValidity' title='GeographicalValidity' mdxType="ExpandablePanel">
      <h5><a href='#servicejourneysummary'>serviceJourneys: Option[Seq[ServiceJourneySummary]]  </a></h5>
      <h5><a href='#pointtopointvalidity'>pointToPointValidity: Option[PointToPointValidity]  </a></h5>
      <h5><a href='#zonalvalidity'>zonalValidity: Option[ZonalValidity] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='geointervaldata'>geointervaldata</h6>
    <ExpandablePanel className='expandable' id='GeoIntervalData' title='GeoIntervalData' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage] </a></h5>
      <span>The distance in KM or TEN for this interval.</span>
      <h5>distance: Option[Double]  </h5>
      <span>The number of zones in this interval.</span>
      <h5>zoneCount: Option[Int]  </h5>
      <span>A list of the zones that exist in this interval. Empty if not relevant.</span>
      <h5>zones: Seq[String]</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='groupconfig'>groupconfig</h6>
    <ExpandablePanel className='expandable' id='GroupConfig' title='GroupConfig' mdxType="ExpandablePanel">
      <span>Number of compartments to group travellers in.</span>
      <h5>numberOfCompartments: Int</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='groupsummary'>groupsummary</h6>
    <ExpandablePanel className='expandable' id='GroupSummary' title='GroupSummary' mdxType="ExpandablePanel">
      <h5>id: String </h5>
      <h5>version: String </h5>
      <h5>name: Option[String]  </h5>
      <h5>description: String </h5>
      <h5><a href='#userprofileingroupsummary'>userProfiles: Seq[UserProfileInGroupSummary]</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='interchangeto'>interchangeto</h6>
    <ExpandablePanel className='expandable' id='InterchangeTo' title='InterchangeTo' mdxType="ExpandablePanel">
      <h5>staySeated: Option[Boolean]  </h5>
      <h5><a href='#servicejourney'>fromServiceJourney: Option[ServiceJourney]  </a></h5>
      <h5><a href='#servicejourney'>toServiceJourney: Option[ServiceJourney] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='journeyorganizealgorithmenum'>JourneyOrganizeAlgorithmEnum</h6>
    <ExpandablePanel className='expandable' title='JourneyOrganizeAlgorithmEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>SUBSEQUENT_COMBINATIONS</p>
      <p>COMBINATIONS_FROM_OFFERS</p>
      <p>FOR_EACH_AND_GROUPED_COMBINATIONS</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='journeyspec'>journeyspec</h6>
    <ExpandablePanel className='expandable' id='JourneySpec' title='JourneySpec' mdxType="ExpandablePanel">
      <h5>startTime: Option[OffsetDateTime]  </h5>
      <h5>endTime: Option[OffsetDateTime]  </h5>
      <span>NeTEx-id of Operator.</span>
      <h5>operator: Option[String]  </h5>
      <span>NeTEx-id of authority.</span>
      <h5>authority: Option[String]  </h5>
      <span>NeTEx-id of line</span>
      <h5>line: Option[String]  </h5>
      <h5><a href='#transportmodeenum'>mode: Option[TransportModeEnum]  </a></h5>
      <h5><a href='#transportsubmodeenum'>transportSubmode: Option[TransportSubModeEnum]  </a></h5>
      <h5><a href='#placeinjourneyspec'>placesInJourney: Option[Seq[PlaceInJourneySpec]] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='legrequest'>legrequest</h6>
    <ExpandablePanel className='expandable' id='LegRequest' title='LegRequest' mdxType="ExpandablePanel">
      <span>The expected date and time this leg starts.</span>
      <h5>travelDate: LocalDate </h5>
      <span>Stop place id for he origin of the leg</span>
      <h5>fromStopPlaceId: String </h5>
      <span>Stop place id for the destination of the leg</span>
      <h5>toStopPlaceId: String </h5>
      <span>Service journey id for the leg</span>
      <h5>serviceJourneyId: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='leg'>leg</h6>
    <ExpandablePanel className='expandable' id='Leg' title='Leg' mdxType="ExpandablePanel">
      <span>The aimed date and time this leg starts.</span>
      <h5>aimedStartTime: OffsetDateTime </h5>
      <span>The aimed date and time this leg ends.</span>
      <h5>aimedEndTime: OffsetDateTime </h5>
      <span>The expected date and time this leg starts.</span>
      <h5>expectedStartTime: Option[OffsetDateTime]  </h5>
      <span>The expected date and time this leg ends.</span>
      <h5>expectedEndTime: Option[OffsetDateTime]  </h5>
      <h5><a href='#transportmodeenum'>mode: TransportModeEnum </a></h5>
      <h5><a href='#transportsubmodeenum'>transportSubmode: Option[TransportSubModeEnum]  </a></h5>
      <h5><a href='#operator'>operator: Option[Operator]  </a></h5>
      <h5><a href='#authority'>authority: Option[Authority]  </a></h5>
      <span>Whether this leg is a ride leg or not.</span>
      <h5>ride: Boolean </h5>
      <h5><a href='#place'>fromPlace: Place </a></h5>
      <h5><a href='#place'>toPlace: Place </a></h5>
      <h5><a href='#line'>line: Option[Line]  </a></h5>
      <h5><a href='#servicejourney'>serviceJourney: Option[ServiceJourney]  </a></h5>
      <h5><a href='#datedservicejourney'>datedServiceJourney: Option[DatedServiceJourney]  </a></h5>
      <h5><a href='#estimatedcall'>fromEstimatedCall: Option[EstimatedCall]  </a></h5>
      <h5><a href='#estimatedcall'>toEstimatedCall: Option[EstimatedCall]  </a></h5>
      <span>For ride legs, intermediate quays between the Place where the leg originates and the Place where the leg ends. For non-ride legs, null.</span>
      <h5><a href='#quay'>intermediateQuays: Seq[Quay] </a></h5>
      <h5><a href='#pointsonlink'>pointsOnLink: Option[PointsOnLink]  </a></h5>
      <h5><a href='#interchangeto'>interchangeTo: Option[InterchangeTo]  </a></h5>
      <h5><a href='#quay'>maybeFromQuay: Option[Quay]  </a></h5>
      <h5><a href='#quay'>maybeToQuay: Option[Quay]  </a></h5>
      <h5>hasToFromQuays: Option[Boolean] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='line'>line</h6>
    <ExpandablePanel className='expandable' id='Line' title='Line' mdxType="ExpandablePanel">
      <span>ID of the Line</span>
      <h5>id: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='linevalidity'>linevalidity</h6>
    <ExpandablePanel className='expandable' id='LineValidity' title='LineValidity' mdxType="ExpandablePanel">
      <span>Valid on lines</span>
      <h5>validOn: Seq[String] </h5>
      <span>Not valid on lines</span>
      <h5>notValidOn: Seq[String]</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='offer'>offer</h6>
    <ExpandablePanel className='expandable' id='Offer' title='Offer' mdxType="ExpandablePanel">
      <span>The ID of the OFFER.</span>
      <h5><a href='#uuid'>id: UUID </a></h5>
      <span>The requested distribution channel</span>
      <h5>requestedByDistributionChannel: Option[String]  </h5>
      <h5><a href='#pricedresourceref'>pricedResource: Option[PricedResourceRef]  </a></h5>
      <h5><a href='#salespackageconfiguration'>salesPackageConfig: SalesPackageConfiguration </a></h5>
      <span>Traveller limitations that applies to the SALES PACKAGE CONFIGURATION.</span>
      <h5><a href='#travellermapping'>travellerMapping: Seq[TravellerMapping]</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='offersummary'>offersummary</h6>
    <ExpandablePanel className='expandable' id='OfferSummary' title='OfferSummary' mdxType="ExpandablePanel">
      <h5>id: String </h5>
      <h5>name: String </h5>
      <h5>description: String </h5>
      <h5><a href='#pricesummary'>price: PriceSummary </a></h5>
      <h5><a href='#geographicalvalidity'>geographicalValidity: GeographicalValidity </a></h5>
      <h5><a href='#preassignedproduct'>preassignedProducts: Seq[PreassignedProduct] </a></h5>
      <h5>optionalProductsSelectableIds: Option[Seq[String]]  </h5>
      <h5><a href='#travellermappingsummary'>travellerMapping: Option[Seq[TravellerMappingSummary]]  </a></h5>
      <span>Antall tilbud tilgjengelig for å kjøpe</span>
      <h5>available: Option[Int]  </h5>
      <h5><a href='#cappeddiscountoption'>cappedDiscountOption: Option[CappedDiscountOption] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='offertobuy'>offertobuy</h6>
    <ExpandablePanel className='expandable' id='OfferToBuy' title='OfferToBuy' mdxType="ExpandablePanel">
      <span>Id of the offer to buy</span>
      <h5>id: String </h5>
      <span>Number of this offer to buy</span>
      <h5>numberToBuy: Int </h5>
      <span>List of selectableIds of supplement products which upgrades a product</span>
      <h5>selectableProductIds: Seq[String] </h5>
      <span>One possible grouping of travellers for the number of offers to buy</span>
      <h5>possibleTravellerIds: Seq[Seq[String]]</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='operator'>operator</h6>
    <ExpandablePanel className='expandable' id='Operator' title='Operator' mdxType="ExpandablePanel">
      <span>NeTEx-id of Operator.</span>
      <h5>id: Option[String]  </h5>
      <span>Name of Operator.</span>
      <h5>name: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='optionalproduct'>optionalproduct</h6>
    <ExpandablePanel className='expandable' id='OptionalProduct' title='OptionalProduct' mdxType="ExpandablePanel">
      <h5>id: String </h5>
      <h5>version: String </h5>
      <h5>selectableId: String </h5>
      <h5>name: String </h5>
      <h5>description: String </h5>
      <h5><a href='#pricesummary'>price: PriceSummary </a></h5>
      <h5><a href='#propertiessummary'>properties: PropertiesSummary </a></h5>
      <h5><a href='#geographicalvalidity'>geographicalValidity: GeographicalValidity </a></h5>
      <h5><a href='#discountrightsummary'>discountRight: Option[DiscountRightSummary]  </a></h5>
      <span>Antallet valgfrie produkter som er tilgjengelig for å kjøpe</span>
      <h5>available: Option[Int]  </h5>
      <h5><a href='#supplementproducttypeenum'>supplementProductType: Option[SupplementProductTypeEnum] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='organisationconfig'>organisationconfig</h6>
    <ExpandablePanel className='expandable' id='OrganisationConfig' title='OrganisationConfig' mdxType="ExpandablePanel">
      <span>The netexId for the organisation.</span>
      <h5>id: String </h5>
      <span>The privateCode for the organisation, used to integrate with the organisation register.</span>
      <h5><a href='#long'>privateCode: Option[Long] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='organisationsummary'>organisationsummary</h6>
    <ExpandablePanel className='expandable' id='OrganisationSummary' title='OrganisationSummary' mdxType="ExpandablePanel">
      <span>the netex-id of organisation</span>
      <h5>id: String </h5>
      <span>the name of the organisation</span>
      <h5>name: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='placeinjourneyspec'>placeinjourneyspec</h6>
    <ExpandablePanel className='expandable' id='PlaceInJourneySpec' title='PlaceInJourneySpec' mdxType="ExpandablePanel">
      <h5><a href='#stopplace'>stopPlace: Option[StopPlace]  </a></h5>
      <h5>zoneId: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='place'>place</h6>
    <ExpandablePanel className='expandable' id='Place' title='Place' mdxType="ExpandablePanel">
      <h5>name: Option[String]  </h5>
      <h5><a href='#quay'>quay: Option[Quay] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='pointsonlink'>pointsonlink</h6>
    <ExpandablePanel className='expandable' id='PointsOnLink' title='PointsOnLink' mdxType="ExpandablePanel">
      <span>The encoded points of the polyline.</span>
      <h5>points: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='pointtopointvalidity'>pointtopointvalidity</h6>
    <ExpandablePanel className='expandable' id='PointToPointValidity' title='PointToPointValidity' mdxType="ExpandablePanel">
      <span>Valid from place</span>
      <h5>fromPlace: String </h5>
      <span>Valid to place</span>
      <h5>toPlace: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='preassignedproduct'>preassignedproduct</h6>
    <ExpandablePanel className='expandable' id='PreassignedProduct' title='PreassignedProduct' mdxType="ExpandablePanel">
      <h5>id: String </h5>
      <h5>version: String </h5>
      <h5>name: String </h5>
      <h5>description: String </h5>
      <h5><a href='#propertiessummary'>properties: PropertiesSummary </a></h5>
      <h5><a href='#geographicalvalidity'>geographicalValidity: GeographicalValidity </a></h5>
      <h5><a href='#discountrightsummary'>discountRight: Option[DiscountRightSummary] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='pricecomparisonalgorithmenum'>PriceComparisonAlgorithmEnum</h6>
    <ExpandablePanel className='expandable' title='PriceComparisonAlgorithmEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>TOTAL_PRICE</p>
      <p>BEFORE_SDR</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='pricedgroup'>pricedgroup</h6>
    <ExpandablePanel className='expandable' id='PricedGroup' title='PricedGroup' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>Used for selecting a unique element in the configurations</span>
      <h5>selectableId: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage] </a></h5>
      <span>The price(s) of the sub configurations below this node. Multiple prices is supported in case of prices in multiple currencies.</span>
      <h5><a href='#price'>prices: Seq[Price] </a></h5>
      <span>The price contribution shows how much this node contributes on the total price.</span>
      <h5><a href='#price'>priceContributions: Seq[Price] </a></h5>
      <span>The type of this usage parameter.</span>
      <h5>typeOfUsageParameter: Option[String]  </h5>
      <span>True if this configuration is optional to buy, false if it's required.</span>
      <h5>optional: Boolean </h5>
      <span>Reference to a group</span>
      <h5><a href='#userprofileingroup'>userProfiles: Seq[UserProfileInGroup] </a></h5>
      <h5><a href='#pricingbasisenum'>pricingBasis: Option[PricingBasisEnum]  </a></h5>
      <span>Minimum number of persons in the group</span>
      <h5>minNumberOfPersons: Option[Int]  </h5>
      <span>Maximum number of persons in the group</span>
      <h5>maxNumberOfPersons: Option[Int] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='pricedresourceref'>pricedresourceref</h6>
    <ExpandablePanel className='expandable' id='PricedResourceRef' title='PricedResourceRef' mdxType="ExpandablePanel">
      <span>ID of the resource, e.g. an ID of a 'TripPattern'.</span>
      <h5>id: String </h5>
      <span>Name of the resource.</span>
      <h5>resourceType: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='pricedusageparameter'>pricedusageparameter</h6>
    <ExpandablePanel className='expandable' id='PricedUsageParameter' title='PricedUsageParameter' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>Used for selecting a unique element in the configurations</span>
      <h5>selectableId: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage] </a></h5>
      <span>The price(s) of the sub configurations below this node. Multiple prices is supported in case of prices in multiple currencies.</span>
      <h5><a href='#price'>prices: Seq[Price] </a></h5>
      <span>The price contribution shows how much this node contributes on the total price.</span>
      <h5><a href='#price'>priceContributions: Seq[Price] </a></h5>
      <span>The type of this usage parameter.</span>
      <h5>typeOfUsageParameter: Option[String]  </h5>
      <span>True if this configuration is optional to buy, false if it's required.</span>
      <h5>optional: Boolean</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='priceduserprofile'>priceduserprofile</h6>
    <ExpandablePanel className='expandable' id='PricedUserProfile' title='PricedUserProfile' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>Used for selecting a unique element in the configurations</span>
      <h5>selectableId: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The price(s) of the sub configurations below this node. Multiple prices is supported in case of prices in multiple currencies.</span>
      <h5><a href='#price'>prices: Seq[Price] </a></h5>
      <span>The price contribution shows how much this node contributes on the total price.</span>
      <h5><a href='#price'>priceContributions: Seq[Price] </a></h5>
      <span>True if this configuration is optional to buy, false if it's required.</span>
      <h5>optional: Boolean </h5>
      <h5>typeOfConcession: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='price'>price</h6>
    <ExpandablePanel className='expandable' id='Price' title='Price' mdxType="ExpandablePanel">
      <span>The amount.</span>
      <h5>amount: String </h5>
      <span>The VAT group.</span>
      <h5>vatGroup: Option[String]  </h5>
      <span>The currency of the amount.</span>
      <h5>currency: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='pricesummary'>pricesummary</h6>
    <ExpandablePanel className='expandable' id='PriceSummary' title='PriceSummary' mdxType="ExpandablePanel">
      <h5>amount: String </h5>
      <h5>currency: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='pricingbasisenum'>PricingBasisEnum</h6>
    <ExpandablePanel className='expandable' title='PricingBasisEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>PER_OFFER</p>
      <p>PER_PERSON</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='producttypeenum'>ProductTypeEnum</h6>
    <ExpandablePanel className='expandable' title='ProductTypeEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>TRIP_CARNET</p>
      <p>PASS_CARNET</p>
      <p>UNIT_COUPONS</p>
      <p>STORED_VALUE</p>
      <p>OTHER</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='properties'>properties</h6>
    <ExpandablePanel className='expandable' id='Properties' title='Properties' mdxType="ExpandablePanel">
      <h5><a href='#fareclassenum'>fareClasses: Seq[FareClassEnum] </a></h5>
      <h5><a href='#accommodationfacilityenum'>accommodations: Seq[AccommodationFacilityEnum]</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='propertiessummary'>propertiessummary</h6>
    <ExpandablePanel className='expandable' id='PropertiesSummary' title='PropertiesSummary' mdxType="ExpandablePanel">
      <h5>isExchangeable: Option[Boolean]  </h5>
      <h5>isRefundable: Option[Boolean]  </h5>
      <h5><a href='#accommodationforservicejourney'>accommodations: Option[Seq[AccommodationForServiceJourney]]  </a></h5>
      <h5><a href='#baggagetypeenum'>baggageTypes: Option[Seq[BaggageTypeEnum]]  </a></h5>
      <h5><a href='#reservingrequirementforservicejourney'>reservingRequirements: Option[Seq[ReservingRequirementForServiceJourney]]  </a></h5>
      <h5><a href='#organisationsummary'>organisation: OrganisationSummary </a></h5>
      <h5>duration: Option[String]  </h5>
      <h5><a href='#groupsummary'>group: Option[GroupSummary]  </a></h5>
      <h5><a href='#fareclassenum'>fareClasses: Option[Seq[FareClassEnum]]  </a></h5>
      <h5><a href='#carnet'>carnet: Option[Carnet] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='quay'>quay</h6>
    <ExpandablePanel className='expandable' id='Quay' title='Quay' mdxType="ExpandablePanel">
      <h5>id: Option[String]  </h5>
      <span>Name</span>
      <h5>name: Option[String]  </h5>
      <h5><a href='#stopplace'>stopPlace: Option[StopPlace] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='quota'>quota</h6>
    <ExpandablePanel className='expandable' id='Quota' title='Quota' mdxType="ExpandablePanel">
      <span>The corresponding service journey id for this stock</span>
      <h5>serviceJourneyId: String </h5>
      <span>The amount of stock for this priceable object.</span>
      <h5>stock: Int</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='recommendationconfig'>recommendationconfig</h6>
    <ExpandablePanel className='expandable' id='RecommendationConfig' title='RecommendationConfig' mdxType="ExpandablePanel">
      <h5><a href='#categoryspec'>categorySpec: Option[CategorySpec]  </a></h5>
      <h5><a href='#rulespec'>ruleSpec: Option[RuleSpec] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='recommendation'>recommendation</h6>
    <ExpandablePanel className='expandable' id='Recommendation' title='Recommendation' mdxType="ExpandablePanel">
      <h5><a href='#geographicalvaliditycovered'>geographicalValidityCovered: GeographicalValidityCovered </a></h5>
      <h5><a href='#typeofrecommendationenum'>typeOfRecommendation: TypeOfRecommendationEnum </a></h5>
      <h5><a href='#durationenum'>durationType: Option[DurationEnum]  </a></h5>
      <h5><a href='#fareclassenum'>fareClass: Option[FareClassEnum]  </a></h5>
      <h5><a href='#accommodationfacilityforrecommendationsenum'>facilitySet: Option[AccommodationFacilityForRecommendationsEnum]  </a></h5>
      <h5>specifiedProduct: Option[String]  </h5>
      <h5><a href='#offertobuy'>offersToBuy: Seq[OfferToBuy]</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='reftype'>reftype</h6>
    <ExpandablePanel className='expandable' id='RefType' title='RefType' mdxType="ExpandablePanel">
      <h5>id: String </h5>
      <h5>version: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='reservationstatusenum'>ReservationStatusEnum</h6>
    <ExpandablePanel className='expandable' title='ReservationStatusEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>OPEN</p>
      <p>CLOSED</p>
      <p>UNKNOWN</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='reservingrequirementenum'>ReservingRequirementEnum</h6>
    <ExpandablePanel className='expandable' title='ReservingRequirementEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>RESERVATIONS_COMPULSORY</p>
      <p>RESERVATIONS_COMPULSORY_FOR_GROUPS</p>
      <p>RESERVATIONS_COMPULSORY_FOR_FIRST_CLASS</p>
      <p>RESERVATIONS_COMPULSORY_FROM_ORIGIN_STATION</p>
      <p>RESERVATIONS_RECOMMENDED</p>
      <p>RESERVATIONS_POSSIBLE</p>
      <p>RESERVATIONS_POSSIBLE_ONLY_IN_FIRST_CLASS</p>
      <p>RESERVATIONS_POSSIBLE_ONLY_IN_SECOND_CLASS</p>
      <p>RESERVATIONS_POSSIBLE_FOR_CERTAIN_CLASSES</p>
      <p>GROUP_BOOKING_RESTRICTED</p>
      <p>NO_GROUPS_ALLOWED</p>
      <p>NO_RESERVATIONS_POSSIBLE</p>
      <p>WHEELCHAIR_ONLY_RESERVATIONS</p>
      <p>BICYCLE_RESERVATIONS_COMPULSORY</p>
      <p>RESERVATION_SUPPLEMENT_CHARGED</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='reservingrequirementforservicejourney'>reservingrequirementforservicejourney</h6>
    <ExpandablePanel className='expandable' id='ReservingRequirementForServiceJourney' title='ReservingRequirementForServiceJourney' mdxType="ExpandablePanel">
      <h5><a href='#servicejourneysummary'>serviceJourney: ServiceJourneySummary </a></h5>
      <h5><a href='#reservingrequirementenum'>reservingRequirement: Option[ReservingRequirementEnum]  </a></h5>
      <h5>seatMapIsAvailable: Boolean</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='rulespec'>rulespec</h6>
    <ExpandablePanel className='expandable' id='RuleSpec' title='RuleSpec' mdxType="ExpandablePanel">
      <h5><a href='#journeyorganizealgorithmenum'>journeyOrganizeAlgorithm: Option[JourneyOrganizeAlgorithmEnum]  </a></h5>
      <h5><a href='#pricecomparisonalgorithmenum'>priceComparisonAlgorithm: Option[PriceComparisonAlgorithmEnum]  </a></h5>
      <h5>onlyIncludeRecommendedOffers: Option[Boolean]  </h5>
      <h5>onlyIncludeRecommendationsWithOffersToBuy: Option[Boolean]  </h5>
      <h5>mixinOffersWithHigherFlexibility: Option[Boolean] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='salespackageconfiguration'>salespackageconfiguration</h6>
    <ExpandablePanel className='expandable' id='SalesPackageConfiguration' title='SalesPackageConfiguration' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage] </a></h5>
      <span>The price(s) of the sub configurations below this node. Multiple prices is supported in case of prices in multiple currencies.</span>
      <h5><a href='#price'>prices: Seq[Price] </a></h5>
      <span>The price contribution shows how much this node contributes on the total price.</span>
      <h5><a href='#price'>priceContributions: Seq[Price] </a></h5>
      <h5><a href='#properties'>properties: Option[Properties]  </a></h5>
      <h5><a href='#usageparameters'>parameters: Option[UsageParameters]  </a></h5>
      <span>Reference to the legs this configuration is for.</span>
      <h5>serviceJourneyIds: Seq[String] </h5>
      <span>Reference to the legs this configuration is for.</span>
      <h5><a href='#datedservicejourney'>datedServiceJourneys: Seq[DatedServiceJourney] </a></h5>
      <span>References to different distribution parameters.</span>
      <h5><a href='#distributionparameters'>distributions: Seq[DistributionParameters] </a></h5>
      <span>Reference to a Fare Product and its configuration.</span>
      <h5><a href='#fareproductconfiguration'>fareProducts: Seq[FareProductConfiguration] </a></h5>
      <span>Specific parameter assignments</span>
      <h5><a href='#specificparameterassignment'>specificParameterAssignments: Option[Seq[SpecificParameterAssignment]] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='searchresponse'>searchresponse</h6>
    <ExpandablePanel className='expandable' id='SearchResponse' title='SearchResponse' mdxType="ExpandablePanel">
      <h5>tripPatternId: Option[String]  </h5>
      <h5><a href='#offersummary'>offers: Seq[OfferSummary] </a></h5>
      <h5><a href='#recommendation'>recommendations: Option[Seq[Recommendation]]  </a></h5>
      <h5><a href='#optionalproduct'>optionalProducts: Option[Seq[OptionalProduct]]  </a></h5>
      <h5><a href='#unavailableproduct'>unavailableProducts: Option[Seq[UnavailableProduct]]  </a></h5>
      <h5><a href='#explaininfo'>explainInfo: Option[ExplainInfo] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='searchstopplacesrequest'>searchstopplacesrequest</h6>
    <ExpandablePanel className='expandable' id='SearchStopPlacesRequest' title='SearchStopPlacesRequest' mdxType="ExpandablePanel">
      <span>The id of the origin stop place.</span>
      <h5>from: String </h5>
      <span>The id of the destination stop place.</span>
      <h5>to: String </h5>
      <span>The expected start time of travel.</span>
      <h5>travelDate: OffsetDateTime </h5>
      <span>The travellers.</span>
      <h5><a href='#traveller'>travellers: Seq[Traveller] </a></h5>
      <h5><a href='#recommendationconfig'>recommendationConfig: Option[RecommendationConfig]  </a></h5>
      <h5>offersForWholeTripOnly: Option[Boolean] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='searchtrippatternrequest'>searchtrippatternrequest</h6>
    <ExpandablePanel className='expandable' id='SearchTripPatternRequest' title='SearchTripPatternRequest' mdxType="ExpandablePanel">
      <span>The travellers for the set trip</span>
      <h5><a href='#traveller'>travellers: Seq[Traveller] </a></h5>
      <h5><a href='#existingticket'>existingTicket: Option[ExistingTicket]  </a></h5>
      <h5><a href='#recommendationconfig'>recommendationConfig: Option[RecommendationConfig]  </a></h5>
      <h5><a href='#trippatternrequest'>tripPattern: TripPatternRequest </a></h5>
      <h5><a href='#groupconfig'>groupConfig: Option[GroupConfig]  </a></h5>
      <h5><a href='#explainspecification'>explainSpecification: Option[ExplainSpecification] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='searchzonesrequest'>searchzonesrequest</h6>
    <ExpandablePanel className='expandable' id='SearchZonesRequest' title='SearchZonesRequest' mdxType="ExpandablePanel">
      <span>The id of the origin zone.</span>
      <h5>from: String </h5>
      <span>Optional sequence of via zones.</span>
      <h5>via: Option[Seq[String]]  </h5>
      <span>The id of the destination zone.</span>
      <h5>to: String </h5>
      <span>The expected start time of travel.</span>
      <h5>travelDate: OffsetDateTime </h5>
      <span>The travellers.</span>
      <h5><a href='#traveller'>travellers: Seq[Traveller] </a></h5>
      <h5><a href='#existingticket'>existingTicket: Option[ExistingTicket]  </a></h5>
      <h5><a href='#recommendationconfig'>recommendationConfig: Option[RecommendationConfig]  </a></h5>
      <h5><a href='#explainspecification'>explainSpecification: Option[ExplainSpecification] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='searchauthorityrequest'>searchauthorityrequest</h6>
    <ExpandablePanel className='expandable' id='SearchAuthorityRequest' title='SearchAuthorityRequest' mdxType="ExpandablePanel">
      <span>The expected start time of travel.</span>
      <h5>travelDate: OffsetDateTime </h5>
      <span>The travellers.</span>
      <h5><a href='#traveller'>travellers: Seq[Traveller] </a></h5>
      <h5><a href='#explainspecification'>explainSpecification: Option[ExplainSpecification] </a></h5>
      <span>The id of the requested authority</span>
      <h5>authorityId: String </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='serviceenum'>ServiceEnum</h6>
    <ExpandablePanel className='expandable' title='ServiceEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>PRODUCTS</p>
      <p>INVENTORY</p>
      <p>SEATING</p>
      <p>ENTUR_PLASS</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='servicejourney'>servicejourney</h6>
    <ExpandablePanel className='expandable' id='ServiceJourney' title='ServiceJourney' mdxType="ExpandablePanel">
      <h5>id: String </h5>
      <h5>privateCode: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='servicejourneysummary'>servicejourneysummary</h6>
    <ExpandablePanel className='expandable' id='ServiceJourneySummary' title='ServiceJourneySummary' mdxType="ExpandablePanel">
      <h5>datedServiceJourneyId: String </h5>
      <h5>serviceJourneyId: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='specificparameterassignment'>specificparameterassignment</h6>
    <ExpandablePanel className='expandable' id='SpecificParameterAssignment' title='SpecificParameterAssignment' mdxType="ExpandablePanel">
      <span>Specification of parameter id</span>
      <h5>specificationOf: String </h5>
      <span>Limitations</span>
      <h5><a href='#versionedobjectref'>limitations: Option[Seq[VersionedObjectRef]]  </a></h5>
      <span>Validity parameters</span>
      <h5><a href='#versionedobjectref'>validityParameters: Option[Seq[VersionedObjectRef]] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='stopplace'>stopplace</h6>
    <ExpandablePanel className='expandable' id='StopPlace' title='StopPlace' mdxType="ExpandablePanel">
      <span>ID</span>
      <h5>id: String </h5>
      <span>Name of stop place.</span>
      <h5>name: Option[String]  </h5>
      <span>Tariff zones</span>
      <h5><a href='#tariffzone'>tariffZones: Option[Seq[TariffZone]]  </a></h5>
      <span>Longitude</span>
      <h5>longitude: Option[Double]  </h5>
      <span>Latitude</span>
      <h5>latitude: Option[Double] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='supplementproducttypeenum'>SupplementProductTypeEnum</h6>
    <ExpandablePanel className='expandable' title='SupplementProductTypeEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>SEAT_RESERVATION</p>
      <p>BICYCLE</p>
      <p>DOG</p>
      <p>ANIMAL</p>
      <p>MEAL</p>
      <p>WIFI</p>
      <p>EXTRA_LUGGAGE</p>
      <p>PENALTY</p>
      <p>UPGRADE</p>
      <p>JOURNEY_EXTENSION</p>
      <p>JOURNEY_ADD_ON</p>
      <p>EVENT_ADD_ON</p>
      <p>PARKING</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='tariffzone'>tariffzone</h6>
    <ExpandablePanel className='expandable' id='TariffZone' title='TariffZone' mdxType="ExpandablePanel">
      <h5>id: String </h5>
      <h5>name: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='textinlanguage'>textinlanguage</h6>
    <ExpandablePanel className='expandable' id='TextInLanguage' title='TextInLanguage' mdxType="ExpandablePanel">
      <span>Language code.</span>
      <h5>lang: String </h5>
      <span>The text.</span>
      <h5>value: String</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='transportmodeenum'>TransportModeEnum</h6>
    <ExpandablePanel className='expandable' title='TransportModeEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>AIR</p>
      <p>BICYCLE</p>
      <p>BUS</p>
      <p>CABLEWAY</p>
      <p>CAR</p>
      <p>WATER</p>
      <p>FUNICULAR</p>
      <p>LIFT</p>
      <p>RAIL</p>
      <p>METRO</p>
      <p>TRAM</p>
      <p>COACH</p>
      <p>TRANSIT</p>
      <p>FOOT</p>
      <p>CAR_PARK</p>
      <p>CAR_PICKUP</p>
      <p>CAR_DROPOFF</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='transportsubmodeenum'>TransportSubModeEnum</h6>
    <ExpandablePanel className='expandable' title='TransportSubModeEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>UNKNOWN</p>
      <p>UNDEFINED</p>
      <p>INTERNATIONAL_FLIGHT</p>
      <p>DOMESTIC_FLIGHT</p>
      <p>INTERCONTINENTAL_FLIGHT</p>
      <p>DOMESTIC_SCHEDULED_FLIGHT</p>
      <p>SHUTTLE_FLIGHT</p>
      <p>INTERCONTINENTAL_CHARTER_FLIGHT</p>
      <p>INTERNATIONAL_CHARTER_FLIGHT</p>
      <p>ROUND_TRIP_CHARTER_FLIGHT</p>
      <p>SIGHTSEEING_FLIGHT</p>
      <p>HELICOPTER_SERVICE</p>
      <p>DOMESTIC_CHARTER_FLIGHT</p>
      <p>SCHENGEN_AREA_FLIGHT</p>
      <p>AIRSHIP_SERVICE</p>
      <p>SHORT_HAUL_INTERNATIONAL_FLIGHT</p>
      <p>CANAL_BARGE</p>
      <p>LOCAL_BUS</p>
      <p>REGIONAL_BUS</p>
      <p>EXPRESS_BUS</p>
      <p>NIGHT_BUS</p>
      <p>POST_BUS</p>
      <p>SPECIAL_NEEDS_BUS</p>
      <p>MOBILITY_BUS</p>
      <p>MOBILITY_BUS_FOR_REGISTERED_DISABLED</p>
      <p>SIGHTSEEING_BUS</p>
      <p>SHUTTLE_BUS</p>
      <p>HIGH_FREQUENCY_BUS</p>
      <p>DEDICATED_LANE_BUS</p>
      <p>SCHOOL_BUS</p>
      <p>SCHOOL_AND_PUBLIC_SERVICE_BUS</p>
      <p>RAIL_REPLACEMENT_BUS</p>
      <p>DEMAND_AND_RESPONSE_BUS</p>
      <p>AIRPORT_LINK_BUS</p>
      <p>INTERNATIONAL_COACH</p>
      <p>NATIONAL_COACH</p>
      <p>SHUTTLE_COACH</p>
      <p>REGIONAL_COACH</p>
      <p>SPECIAL_COACH</p>
      <p>SCHOOL_COACH</p>
      <p>SIGHTSEEING_COACH</p>
      <p>TOURIST_COACH</p>
      <p>COMMUTER_COACH</p>
      <p>FUNICULAR</p>
      <p>STREET_CABLE_CAR</p>
      <p>ALL_FUNICULAR_SERVICES</p>
      <p>UNDEFINED_FUNICULAR</p>
      <p>METRO</p>
      <p>TUBE</p>
      <p>URBAN_RAILWAY</p>
      <p>CITY_TRAM</p>
      <p>LOCAL_TRAM</p>
      <p>REGIONAL_TRAM</p>
      <p>SIGHTSEEING_TRAM</p>
      <p>SHUTTLE_TRAM</p>
      <p>TRAIN_TRAM</p>
      <p>TELECABIN</p>
      <p>CABLE_CAR</p>
      <p>LIFT</p>
      <p>CHAIR_LIFT</p>
      <p>DRAG_LIFT</p>
      <p>TELECABIN_LINK</p>
      <p>LOCAL</p>
      <p>HIGH_SPEED_RAIL</p>
      <p>SUBURBAN_RAILWAY</p>
      <p>REGIONAL_RAIL</p>
      <p>INTERREGIONAL_RAIL</p>
      <p>LONG_DISTANCE</p>
      <p>INTERNATIONAL</p>
      <p>SLEEPER_RAIL_SERVICE</p>
      <p>NIGHT_RAIL</p>
      <p>CAR_TRANSPORT_RAIL_SERVICE</p>
      <p>TOURIST_RAILWAY</p>
      <p>AIRPORT_LINK_RAIL</p>
      <p>RAIL_SHUTTLE</p>
      <p>REPLACEMENT_RAIL_SERVICE</p>
      <p>SPECIAL_TRAIN</p>
      <p>CROSS_COUNTRY_RAIL</p>
      <p>RACK_AND_PINION_RAILWAY</p>
      <p>INTERNATIONAL_CAR_FERRY</p>
      <p>NATIONAL_CAR_FERRY</p>
      <p>REGIONAL_CAR_FERRY</p>
      <p>LOCAL_CAR_FERRY</p>
      <p>INTERNATIONAL_PASSENGER_FERRY</p>
      <p>NATIONAL_PASSENGER_FERRY</p>
      <p>REGIONAL_PASSENGER_FERRY</p>
      <p>LOCAL_PASSENGER_FERRY</p>
      <p>POST_BOAT</p>
      <p>TRAIN_FERRY</p>
      <p>ROAD_FERRY_LINK</p>
      <p>AIRPORT_BOAT_LINK</p>
      <p>HIGH_SPEED_VEHICLE_SERVICE</p>
      <p>HIGH_SPEED_PASSENGER_SERVICE</p>
      <p>SIGHTSEEING_SERVICE</p>
      <p>SCHOOL_BOAT</p>
      <p>CABLE_FERRY</p>
      <p>RIVER_BUS</p>
      <p>SCHEDULED_FERRY</p>
      <p>SHUTTLE_FERRY_SERVICE</p>
      <p>COMMUNAL_TAXI</p>
      <p>CHARTER_TAXI</p>
      <p>WATER_TAXI</p>
      <p>RAIL_TAXI</p>
      <p>BIKE_TAXI</p>
      <p>BLACK_CAB</p>
      <p>MINI_CAB</p>
      <p>ALL_TAXI_SERVICES</p>
      <p>HIRE_CAR</p>
      <p>HIRE_VAN</p>
      <p>HIRE_MOTORBIKE</p>
      <p>HIRE_CYCLE</p>
      <p>ALL_HIRE_VEHICLES</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='travellermapping'>travellermapping</h6>
    <ExpandablePanel className='expandable' id='TravellerMapping' title='TravellerMapping' mdxType="ExpandablePanel">
      <span>The ids of the travellers.</span>
      <h5>travellerIds: Seq[String] </h5>
      <span>The maximum number of travellers that can be selected from the group.</span>
      <h5>maxNumberOfTravellers: Int </h5>
      <span>The minimum number of travellers that can be selected from the group.</span>
      <h5>minNumberOfTravellers: Int </h5>
      <span>Reference to the user profile associated with this offer.</span>
      <h5>userProfileId: String </h5>
      <span>Version to the user profile associated with this offer.</span>
      <h5>userProfileVersion: String </h5>
      <span>If the request uses traveller groups to specify the travellers this field holds the id of the group that the travellers belongs to.</span>
      <h5>travellerGroupId: Option[String]  </h5>
      <span>If there are a through fare with two operators more user profiles are needed, this list represents these</span>
      <h5>userProfileIds: Seq[String]</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='travellermappingsummary'>travellermappingsummary</h6>
    <ExpandablePanel className='expandable' id='TravellerMappingSummary' title='TravellerMappingSummary' mdxType="ExpandablePanel">
      <span>The ids of the travellers.</span>
      <h5>travellerIds: Seq[String] </h5>
      <span>The maximum number of travellers that can be selected from the group.</span>
      <h5>maxNumberOfTravellers: Int </h5>
      <span>The minimum number of travellers that can be selected from the group.</span>
      <h5>minNumberOfTravellers: Int </h5>
      <h5><a href='#usertypeenum'>userType: UserTypeEnum</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='traveller'>traveller</h6>
    <ExpandablePanel className='expandable' id='Traveller' title='Traveller' mdxType="ExpandablePanel">
      <span>Identifier of the traveller. Only unique within an http-transaction with Products.</span>
      <h5>id: Option[String]  </h5>
      <h5><a href='#usertypeenum'>userType: Option[UserTypeEnum]  </a></h5>
      <h5>age: Option[Int]  </h5>
      <span>A list of references to products that the traveller already owns</span>
      <h5>productIds: Option[Seq[String]]  </h5>
      <h5><a href='#baggagetypeenum'>baggageTypes: Option[Seq[BaggageTypeEnum]]  </a></h5>
      <h5><a href='#cappingspecification'>cappingSpecification: Option[CappingSpecification] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='trippatternrequest'>trippatternrequest</h6>
    <ExpandablePanel className='expandable' id='TripPatternRequest' title='TripPatternRequest' mdxType="ExpandablePanel">
      <span>Legs constituting a suggested sequence of rides and links for this trip</span>
      <h5><a href='#legrequest'>legs: Seq[LegRequest]</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='trippattern'>trippattern</h6>
    <ExpandablePanel className='expandable' id='TripPattern' title='TripPattern' mdxType="ExpandablePanel">
      <span>ID of the trip pattern</span>
      <h5>id: Option[String]  </h5>
      <span>Legs constituting a suggested sequence of rides and links for this trip</span>
      <h5><a href='#leg'>legs: Option[Seq[Leg]]  </a></h5>
      <h5>isPotentialRoundtrip: Option[Boolean]  </h5>
      <h5>expired: Option[Boolean] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='typeofrecommendationenum'>TypeOfRecommendationEnum</h6>
    <ExpandablePanel className='expandable' title='TypeOfRecommendationEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>FLEXIBLE</p>
      <p>SEMI_FLEXIBLE</p>
      <p>NON_FLEXIBLE</p>
      <p>CHEAPEST</p>
      <p>SPECIFIED_PRODUCT</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='unavailableenum'>UnavailableEnum</h6>
    <ExpandablePanel className='expandable' title='UnavailableEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>SOLD_OUT</p>
      <p>CLOSED</p>
      <p>UNKNOWN</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='unavailableproduct'>unavailableproduct</h6>
    <ExpandablePanel className='expandable' id='UnavailableProduct' title='UnavailableProduct' mdxType="ExpandablePanel">
      <h5>id: String </h5>
      <h5><a href='#unavailableenum'>status: UnavailableEnum </a></h5>
      <h5><a href='#servicejourneysummary'>serviceJourney: ServiceJourneySummary</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='updatesearchtrippatternrequest'>updatesearchtrippatternrequest</h6>
    <ExpandablePanel className='expandable' id='UpdateSearchTripPatternRequest' title='UpdateSearchTripPatternRequest' mdxType="ExpandablePanel">
      <span>Travellers to be updated.</span>
      <h5><a href='#traveller'>travellers: Seq[Traveller] </a></h5>
      <h5><a href='#recommendationconfig'>recommendationConfig: Option[RecommendationConfig]  </a></h5>
      <h5><a href='#groupconfig'>groupConfig: Option[GroupConfig]  </a></h5>
      <h5><a href='#explainspecification'>explainSpecification: Option[ExplainSpecification] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='usageparameters'>usageparameters</h6>
    <ExpandablePanel className='expandable' id='UsageParameters' title='UsageParameters' mdxType="ExpandablePanel">
      <h5><a href='#priceduserprofile'>userProfile: Option[PricedUserProfile]  </a></h5>
      <span>Reference to a refund parameter with price information.</span>
      <h5><a href='#pricedusageparameter'>refunds: Seq[PricedUsageParameter] </a></h5>
      <h5><a href='#pricedusageparameter'>usageValidityPeriod: Option[PricedUsageParameter]  </a></h5>
      <span>Reference to luggage allowance parameters with price information.</span>
      <h5><a href='#pricedusageparameter'>luggageAllowances: Seq[PricedUsageParameter] </a></h5>
      <span>Reference to exchanging parameters with price information.</span>
      <h5><a href='#pricedusageparameter'>exchanging: Seq[PricedUsageParameter] </a></h5>
      <span>Reference to reserving parameters with price information.</span>
      <h5><a href='#pricedusageparameter'>reserving: Seq[PricedUsageParameter] </a></h5>
      <span>Reference to replacing parameters with price information.</span>
      <h5><a href='#pricedusageparameter'>replacing: Seq[PricedUsageParameter] </a></h5>
      <span>Reference to purchase window parameters with price information.</span>
      <h5><a href='#pricedusageparameter'>purchaseWindow: Seq[PricedUsageParameter] </a></h5>
      <span>Reference to entitlement given parameters with price information.</span>
      <h5><a href='#pricedusageparameter'>entitlementGiven: Seq[PricedUsageParameter] </a></h5>
      <span>Reference to round trip parameters with price information</span>
      <h5><a href='#pricedusageparameter'>roundTrip: Seq[PricedUsageParameter] </a></h5>
      <h5><a href='#pricedgroup'>group: Option[PricedGroup]  </a></h5>
      <span>Reference to transferability parameters with price information.</span>
      <h5><a href='#pricedusageparameter'>transferability: Seq[PricedUsageParameter]</a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='userprofileingroup'>userprofileingroup</h6>
    <ExpandablePanel className='expandable' id='UserProfileInGroup' title='UserProfileInGroup' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage] </a></h5>
      <h5>typeOfConcession: Option[String]  </h5>
      <span>The number of people in the group.</span>
      <h5>numberOfPeople: Int</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='userprofileingroupsummary'>userprofileingroupsummary</h6>
    <ExpandablePanel className='expandable' id='UserProfileInGroupSummary' title='UserProfileInGroupSummary' mdxType="ExpandablePanel">
      <h5>id: String </h5>
      <h5>version: Option[String]  </h5>
      <h5>numberOfPeople: Int</h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='usertypeenum'>UserTypeEnum</h6>
    <ExpandablePanel className='expandable' title='UserTypeEnum' mdxType="ExpandablePanel">
      <h5>ENUM: </h5> <h5>Allowed values:<br /><br /></h5>
      <p>ANYONE</p>
      <p>ADULT</p>
      <p>CHILD</p>
      <p>INFANT</p>
      <p>ANIMAL</p>
      <p>SENIOR</p>
      <p>GUIDE_DOG</p>
      <p>YOUTH</p>
      <p>STUDENT</p>
      <p>MILITARY</p>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='validableelementconfiguration'>validableelementconfiguration</h6>
    <ExpandablePanel className='expandable' id='ValidableElementConfiguration' title='ValidableElementConfiguration' mdxType="ExpandablePanel">
      <span>The ID of the object on the format [ context : type : instance name ]</span>
      <h5>id: String </h5>
      <span>The version of the object</span>
      <h5>version: String </h5>
      <span>The name of the object.</span>
      <h5><a href='#textinlanguage'>name: Seq[TextInLanguage] </a></h5>
      <span>The description of the object.</span>
      <h5><a href='#textinlanguage'>description: Seq[TextInLanguage] </a></h5>
      <span>The price(s) of the sub configurations below this node. Multiple prices is supported in case of prices in multiple currencies.</span>
      <h5><a href='#price'>prices: Seq[Price] </a></h5>
      <span>The price contribution shows how much this node contributes on the total price.</span>
      <h5><a href='#price'>priceContributions: Seq[Price] </a></h5>
      <h5><a href='#properties'>properties: Option[Properties]  </a></h5>
      <h5><a href='#usageparameters'>parameters: Option[UsageParameters]  </a></h5>
      <span>Reference to the legs this configuration is for.</span>
      <h5>serviceJourneyIds: Seq[String] </h5>
      <span>Reference to the legs this configuration is for.</span>
      <h5><a href='#datedservicejourney'>datedServiceJourneys: Option[Seq[DatedServiceJourney]]  </a></h5>
      <span>Reference to a Fare Structure Element and its configuration.</span>
      <h5><a href='#farestructureelementconfiguration'>fareStructureElements: Seq[FareStructureElementConfiguration] </a></h5>
      <span>How many repeatable validable elements</span>
      <h5>occurs: Option[Int]  </h5>
      <span>Specific parameter assignments</span>
      <h5><a href='#specificparameterassignment'>specificParameterAssignments: Option[Seq[SpecificParameterAssignment]] </a></h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='versionedobjectref'>versionedobjectref</h6>
    <ExpandablePanel className='expandable' id='VersionedObjectRef' title='VersionedObjectRef' mdxType="ExpandablePanel">
      <span>Versioned object ref id</span>
      <h5>id: String </h5>
      <span>Versioned object ref version</span>
      <h5>version: Option[String] </h5>
    </ExpandablePanel>
    <h6 className='hiddenLink' id='zonalvalidity'>zonalvalidity</h6>
    <ExpandablePanel className='expandable' id='ZonalValidity' title='ZonalValidity' mdxType="ExpandablePanel">
      <h5>zones: Option[Seq[String]] </h5>
    </ExpandablePanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      